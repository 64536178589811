@import './variables.scss';

@media (max-width: 601px) {

    .sectionTitle {
        margin: 0 auto;
        color: $h1;
        font-size: 22px;
        text-align: center;
        padding: 15px;
        font-style: normal;
        font-family: sans-serif;
    }


   /* Header Section */ 
    

   .headerBar img {
    width: 90px;
    height: auto;
    margin-top: 5px;

    }

    .headerBar .button {
      width: 110px;
      margin-top: 10px;
      font-size: 12px;
      float: right;
    }

    /* Menu Icon */
    .menuHamIcon {
      margin-top: -10px;
      text-align: center;
    }


    .footer {
        background: #f9f9f9;
        height: 100px;
        clip-path: ellipse(125% 100% at 98.45% 0%);
      }
      
      .footer img {
        margin-top: 25px;
        float: left;
        width: 70px;
        padding-left: 10px;
      }

      .siteLogo {
        color: $white;
        margin-top:-27px;
        font-size: 11px;
        padding-left: 5px;
      }

    .appWrapper p {
        font-size: 14px;
        margin: 0 auto;
        width: 90%;
        line-height: normal;
        font-family: sans-serif;
    }

    .appWrapper h3{
        font-size: 24px;
    }

    .MuiButton-root{
      background: $button;
      color: $white;
      font-size: 9px;
      width: 135px;
      text-align: center;
      display: block;
      margin: 0 auto;
      margin-bottom: 0;
      overflow: hidden;
      position: relative;
      transform: translatez(0);
  }

  /* Nav Bar */ 
    .desktopNav {
        display: none;
    }

    .mobileNavMenu {
        display: block;
    }

    .mobileNav .logo {
      width: 100px;
    }

    .mobileNavLinks a {
      text-decoration: none;
      color: $white;
      font-size: 16px;
  }
  .mobileNavLinks a:link{
      text-decoration: none;
      color: $white;
      font-size: 16px;
  }

  .mobileNavLinks a:hover {
      color: $secondary;
  }

    .navLogo {
        width: 75px;
        height: 30px;
    }

     /* Menu Icon */
     .menuHamIcon {
      margin-top: -10px;
      text-align: center;
    }

      /* Page Title */ 

       .pageTitleSection {
        color: $pageTitleColor;
        font-size: $pageTitleFontSize;
        text-align: $left;
        text-transform: $pageTitleUppercase;
        background-image: $pageTitleBckGrdImg;
        padding: 10px 0 5px 30px;
        margin-top: -10px;
      }


    /* Page Content */ 


/* About AI Home Section */ 
    .aboutAIContainer {
        background: transparent;
        width: 100%;
        height: 100%;
      
      }
      .aboutAIContainer .aboutTitle {
        color: #71d5fa;
        margin: -10px auto;
        font-size: 20px;
        position: absolute;
        padding-left: 20px;
        background: transparent;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
      }
    
      .aboutAIContainer .aboutTitle .line {
        background: $aboutAiLineBackground;
        color: $white;
        display: $aboutAiLineDisplay;
        padding: $aboutAiLinePadding;
        text-decoration: $aboutAiLineTextDecoration;
        transition: $aboutAiLineTransition;
      }
    
    .aboutAIContainer .banner:hover {
     color: $aboutAiBannerHoverColor;
      --underline-width: 100%;
      
    }

    .aboutAIContainer img {
      width: 100%;
      margin-top: -75px;
    }


/* Approach */ 

    .approachAIContainer .columns .title {
      color: $primary;
      font-size: 18px;
   }

   .approachAIContainer h3 {
    font-size: 18px;
  }

  .approachAIContainer h3 .siteTitle {
     color: $primary;
  }

   

    /* Approach Home Section */
    .approachAIContainer {
      background-image: linear-gradient(to bottom, #f1f9ff, #b7d6ea);
        height: 100%;
        font-size: 18px;
    }

    .approachAIContainer h3 {
      font-size: 25px;
    }

    .approachAIContainer h3 .siteTitle {
       color: $primary;
    }

    .approachAIContainer .columns {
      text-align: left;
      padding: 30px;
      margin: 0 auto;
    }


    .approachAIContainer img {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      border-radius: 5px;
    }

   
  /* Videos */ 
    .videoDemoCarouselContainer video {
      width: 85%;
      height: auto;
  }

    .videoDemoCarouselContainer {
        height: auto;
        width: 100%;
        padding-top: 30px;
        margin-top: 50px;
      }


      .videoCarousel {
          padding-top: 50px;
          padding-bottom: 20px;
         background: $primary;
      }

      .videoDemoCarouselContainer h3 {
        font-size: 40px;
        margin-top: 50px;
      }
      
      
      .videoDemoCarouselContainer video {
        border-radius: 7px;
      }
      
      .videoDemoCarouselContainer h5 {
        color: $white;
        margin-top: 50px;
        font-size: 16px;
      }

     /* FAQ Section */  

    .faqContentContainer {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        margin: 25px auto;
        word-wrap: break-word;
      }

      .faqContentContainer  .accordionContainer{
        background-image: linear-gradient(to bottom, #f3f8fc, #e1f1fc);
        padding: 25px;
        border-radius: 10px;
      } 
  
      .faqContentContainer .category {
        font-size: 24px;
        color: #a4bfd0;
        text-align: center;
        padding: 20px;
      }

      .faqContentContainer .titleLine {
        width: 90%;
        height: 2px;
        background-image: linear-gradient(to left, #f8fcff, #e1f1fc);
      }

    .faqContentContainer h5 {
          font-size: 16px;
    }
      
    .faqContentContainer p {
       margin: 0 auto;
        font-size: 16px;
        width: 100%;
        color: #333;
        text-align: left;
    }

      /* About Us Section */ 
      .aboutUsPageContainer {
        flex-grow: 1;
        width: 90%;
        height: 100%;
        margin: 0 auto;
        
      }

      .aboutUsPageContainer .pageTitle {
        color: $primary2;
        font-weight: bold;
        font-size: 22px;
      }

      .aboutUsPageContainer .aboutUsInfoSection{
        margin: 0 auto;
       
      }

      .aboutUsPageContainer .aboutUsInfoSection .title{
        font-size: 22px;
        background-image:  linear-gradient(to left, #dbebf6, #fdfeff);
        color: $primary;
        padding: 15px;
      }

      .aboutUsPageContainer .aboutUsInfoSection .description{
        font-size: 18px;
        color: #333;
        padding-bottom: 50px;
        padding-left: 25px;
        padding-right: 25px;
        height: auto;
      }

      
    /* Demos Video Section */ 

    .demoContainer .videoThumbnailContainer {
      height: 800px;
      background: $cardColor;
      border-radius: 10px;
      overflow: auto;
      text-align: left;
      margin: 0;
    }
    
    .demoContainer .videoThumbnailContainer .Link:hover {
      background: $link;
      animation: fadeInAnimation ease 1s; 
      animation-iteration-count: 1; 
      animation-fill-mode: forwards; 
    }

    .demoContainer .videoThumbnailContainer  .listMediaTitle:hover  {
      color: $white;
    }
    
    .demoContainer .contentContainer {
      background-image: linear-gradient(to bottom, #f1f9ff, #f6fcff);
      height: 100%;
    }

    .demoContainer  h6 {
      font-size: 12px;
    }

    .demoContainer small {
      display: none;
    }


    /* investmentsContentContainer */ 
    .investmentsContentContainer {
      margin: 0 auto;
    
    }

    .investmentsContentContainer  .container{
      margin: 0 auto;
    
    }

    .investmentsContentContainer .title{
      text-align: left;
      padding-left: 5px;
      color: $primary2;
      font-size: 17px;
    }

    .investmentsContentContainer img {
      width: 95%;
      margin: 0 auto;
    
    }


    /* About Us Section */ 
    
    .aboutUsPageContainer {
      flex-grow: 1;
      width: 90%;
      height: 100%;
      margin: 0 auto;
      
    }

    .aboutUsPageContainer .pageTitle {
      color: $primary2;
      font-weight: bold;
      font-size: 22px;
    }

    .aboutUsPageContainer .aboutUsInfoSection{
      margin: 0 auto;
     
    }

    .aboutUsPageContainer .aboutUsInfoSection .title{
      font-size: 22px;
      background-image:  linear-gradient(to left, #dbebf6, #fdfeff);
      color: $primary;
      padding: 15px;
    }

    .aboutUsPageContainer .aboutUsInfoSection .description{
      font-size: 18px;
      color: #333;
      padding-bottom: 50px;
      padding-left: 25px;
      padding-right: 25px;
      height: auto;
    }

    .aboutUsPageContainer .profileContainer {
      margin: 25px auto;

    }

    .profileContainer  .profileInfo {
      margin: 50px auto;

    }



    .profileContainer  .profileInfo .name{
      font-size: 20px;
      color: $primary2;
      font-weight: bold;
     }

     .profileContainer  .profileInfo .title a{
      font-size: $aboutUsProfileInfoTitleFontSize;
      color: $aboutUsProfileInfoTitleColor;
      text-decoration: none;
     }

     .profileContainer  .profileInfo .email{
      font-size: 15px;
      color: #7a99b0;
     }
    


    /* Video Carousel Home Page Section */ 

    .videoDemoCarouselContainer {
      height: auto;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 30px;
    }

    .videoDemoCarouselContainer .sectionTitle {
      text-align: left;
    }

    .videoDemoCarouselContainer .sectionDescription {
      font-size: $desktopFontSize;
      margin: 0 auto;
      text-align: left;
    }

    .videoDemoCarouselContainer .sectionDescription .title {
      margin-left: 20px;
    }

    .videoDemoCarouselContainer .sectionDescription .text {
      text-align: left;
      font-size: 15px;
      width: 90%;
      margin: 0 auto;
    }

    .videoDemoCarouselContainer .button {
    margin-top: 15px;
     border-radius: 50px;
     height: 60px;
     width: 150px;
     padding: 15px;
     background-color: transparent;
     color: $primary;
     border: 2px solid $primary;
     font-size: 14px;
    }


    .videoDemoCarouselContainer video {
          width: 90%;
          height: auto;
          box-shadow: 0 10px 10px 0 #03365b;
      }

    .videoCarousel {
        padding-top: 50px;
        padding-bottom: 20px;
        background: $secondary;
       
    }


    .videoDemoCarouselContainer h3 {
      font-size: 40px;
      margin-top: 50px;
    }
    
    
    .videoDemoCarouselContainer video {
      border-radius: 7px;
    }
    
    .videoDemoCarouselContainer h5 {
      color: $primary;
      font-size: 22px;
    }


   
        /* OFFERINGS Page Section */ 

        .offeringsContentContainer {
          flex-grow: $offeringsContentContainerFlex;
          width: $offeringsContentContainerWidth;
          height: $offeringsContentContainerHeight;
          margin: $offeringsContentContainerMargin;
      }

      .offeringsContentContainer h5 {
        color: $offeringsH5Color;
      }

       /* Partners Container */ 
      .partnersContainer h5 {
        text-align: left;
        color: $primary;
      }

      
  /* Contact Us Container */
  .contactPageContainer {
    height: 100%;
  }
  .contactUsContentContainer {
   height: 100%;
  }

  .contactUsContentContainer h4 {
    color: $primary;
    font-size: 22px;

  }

      .multiActionCard {
        padding-bottom: 150px;
      }

       /* Home Buttons sectiion - Landing Page */ 
    .homeButtons {
      margin: -10px auto;
      width: 100%;
      height: 200px;
      background: #f1f9ff;
    }

    .homeButtons .MuiButton-root {
      height: 65px;
      font-size: 15px;
    }

    .homeButtons .MuiButtonGroup-root {
      margin-top:60px;
    }


    /* Component */ 
    .Card {
        text-align: center;
        background: transparent;
        
    } 


    .Card .cardText {
        font-size: 18px;
        width: 75%;
        word-wrap: break-word;
        text-align: left;
        color: $body1;
        margin: 0 auto;
    }
    
    .Card h5 {
        color: $primary;
        font-size: 18px;
        width: 85%;
        height: 65px;
        margin: 0 auto;
        border-radius: 10px 10px 0 0;
        color: #FFFFFF;
        word-wrap: break-word;

    }

     /* Partners Card */ 
     .PartnersCard {
      padding: 20px;
    }

     /* Profile Card Section */ 
     .ProfileCard {
      width: 100%;
      margin: 0 auto;
  }

    .ProfileCard img {
        text-align: center;
        margin: 0 auto;
        height: 200px;
        border-radius: 10px;
        box-shadow: 5px 10px 8px 5px rgb(113, 113, 113);
      
    }

    .badgeComponentContainer {
      padding-top: 20px;
      text-align: left;
      margin: 0;
    }

    .profileContainer  .profileInfo .name{
      font-size: 18px;
      color: $primary2;
      font-weight: bold;
      padding-bottom: 10px;
     }

    .profileContainer  .profileInfo .email{
      font-size: 12px;
      color: $primary2;
      font-weight: bold;
     }


     .profileContainer  .profileInfo .email a{
      font-size: $aboutUsProfileInfoTitleFontSize;
      color: $aboutUsProfileInfoTitleColor;
      text-decoration: none;
     }
   
 


}



