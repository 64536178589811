@import './variables.scss';

/* General */ 

body{
    background: $bodyBackgroundColor;
    font-size: $bodyFontSize;
    font-family: $bodyFontFamily;
}

.appWrapper {
    flex-grow: $appWrapperFlex;
    @media (max-width: $desktopResWidth) {
        font-size: $appWrapperFontSize; 
    }
}

/* Header Bar */
.MuiAppBar-root {
    background: $appBarBckgrdColor;
}

/* Align Items */ 
.marginCenter {
    margin: $marginCenter;
    text-align:$marginCenterTxtAlign;
}



/* Fab */ 
.MuiFab-root {
    background: $primary;
    color: $white;
}

/* Menu */
.MuiTab-root{
    background: transparent;
    width: 25px;
    color: $white;
    margin: 0 auto;
    text-align: center;
}

/* List */

.MuiList-root {
    background: transparent;
}

.MuiListItem-root{
    color: $primary;

}

/* Link */ 
.MuiLink-root {
    color: $primary;
 
}



/* Spacing */ 
.spacer {
    background: transparent;
    width: 100%;
    height: 30px;

}

.pageSpacer {
    background: transparent;
    width: 100%;
    height: 70px;

}

.pageSubTitleSection {
    color: $pageTitleColor;
    font-size: $pageTitleFontSize;
    text-align: $left;
    text-transform: $pageTitleUppercase;
    padding: $pageTitlePadding;
    text-align: $pageSubTitleAlign;
}


/* Divider */ 
.footerShape {
    width: 100%;
    height: 4px;
    transform: skew(-10deg);
    background-image: linear-gradient(to right, #e4f4ff, #0b80d4);
  }

.dividerShape {
    margin: 0 auto;
    width: 75%;
    height: 2px;
    background-image: linear-gradient(to right, #ffffff, #95d0fb,#ffffff);
 
  }

.dividerShape2 {
    margin: 0 auto;
    width: 75%;
    height: 2px;
    background-image: linear-gradient(to right, $primary, #ffffff,$primary);
 
  }

  .dividerShapeDotted {

    border: rgb(172, 172, 172) dashed 1px;
  }

  .dividerShapeDotted2 {
    margin: 0 auto;
    float: center;
     width: 100%;
     height: 1px;
    border: $secondary dotted 2px;
   }

   .lineDivider {
    width: 100%;
    background-image: linear-gradient(to bottom, #f1f9ff, #b7d6ea);
    height: 1px;
   
}

/* Padding */ 
.bottomPadding {
    padding-bottom: 50px;
}
 
/* Badge */ 
.MuiBadge-colorPrimary {
    background: $h1;
}

/* Accordion */ 
.MuiAccordionSummary-root {
    background: $accordion;
}

.MuiAccordionDetails-root {
    width: 85%;
    margin: 0 auto;
    padding: 20px;
}

/* Form */ 
.MuiFormControl-root {
    width: 65%;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 25px;
    padding: 10px;
}

/* Button */ 
.MuiButton-root {
    width: 200px;
    height: 35px;
    margin: 0 auto;
}

/* Chip */ 
.MuiChip-root {
    margin-left: 10px;
    float: right;
}

